<template>
   <v-container fluid>
    <TitleBar :title="'ติดตามข้อมูล'" :subtitle="''">
      <template slot="action">
          <v-btn large color="primary" @click="exportToXLS('monitor', items)" outlined><v-icon>mdi-export-variant</v-icon><span class="text-title-2">Export</span></v-btn>
      </template>
    </TitleBar>
      <FilterBar>
      <template v-slot:left>
       <v-col cols="12" md="2" >
          <TextField v-model="search" :title="'ค้นหา'" append-icon="mdi-magnify" placeholder="ชื่อ"/>
         </v-col>
            <v-col cols="12" md="2" >
                <Select :title="'กลุ่ม'" :placeholder="'โปรดเลือก'" :items="groupItems" v-model="group" item-text="groupName" return-object/>
              </v-col>
              <v-col cols="12" md="2" >
                  <DatePicker :title="'ตั้งแต่วันที่'" :placeholder="'โปรดเลือก'" v-model="startDate"/>
              </v-col>
              <v-col cols="12" md="2" >
                  <DatePicker :title="'ถึงวันที่'" :placeholder="'โปรดเลือก'" v-model="endDate"/>
              </v-col>
      </template>
      <template v-slot:right>
               <v-col cols="12" md="2" >
                <Select :title="'ชื่อฟอร์ม'" :placeholder="'โปรดเลือก'" :items="formListItems" v-model="formId" item-text="name" return-object />
              </v-col>
              <v-col cols="12" md="2" >
    <v-btn large color="primary" class="mt-6" @click="getResult" :disabled="dataLoading"><span class="text-title-2">เรียกดูข้อมูล</span></v-btn>
    </v-col>
      </template>
    </FilterBar>
    <DataTable
    :headers="headers"
    :loading="dataLoading"
    :items="items"
    :footer-props="{
    'items-per-page-options': [10, 50, 100]
  }"
    :items-per-page="50">
      <template v-for="objectColumn in objectColumns" v-slot:[`item.${objectColumn.value}`]="{ item }">
    <div :key="objectColumn.value">
          {{(typeof item[objectColumn.value] === 'object')? item[objectColumn.value].title: item[objectColumn.value]}}
    </div>
      </template>
       <template v-slot:item.createDate="{item}">
        {{$dayjs(new Date(item.createDate) ).locale('th').format('DD MMMM BBBB HH:mm')}}
      </template>
       <template v-slot:item.monitorDate="{item}">
        {{$dayjs(new Date(item.monitorDate) ).locale('th').format('DD MMMM BBBB HH:mm')}}
      </template>
        <template v-for="numberColumn in numberColumns" v-slot:[`item.${numberColumn.value}`]="{ item }">
    <div :key="numberColumn.value">
      <v-chip :color="getAlertColor(numberColumn,item[numberColumn.value])">
          {{item[numberColumn.value]}}
      </v-chip>
    </div>
      </template>
       <template v-for="locationColumn in locationColumns" v-slot:[`item.${locationColumn.value}`]="{ item }">
    <div :key="locationColumn.value">
<v-btn icon v-if="item[locationColumn.value]" @click="getMap(item[locationColumn.value])"><v-icon>mdi-google-maps</v-icon></v-btn>
    </div>
      </template>
 <template v-for="imageColumn in imageColumns" v-slot:[`item.${imageColumn.value}`]="{ item }">
    <div :key="imageColumn.value">
<v-btn icon @click="getImage(item[imageColumn.value])"><v-icon>mdi-file-image</v-icon></v-btn>
    </div>
      </template>
       <template v-slot:item.action="{item}">
         <v-row>
            <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-btn icon  v-bind="attrs"
          v-on="on" @click="onSaveData(item)">
            <v-icon>mdi-content-save</v-icon>
         </v-btn>
      </template>
      <span>บันทึกข้อมูล</span>
    </v-tooltip>
            <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-btn icon  v-bind="attrs"
          v-on="on" @click="viewHistory(item)">
            <v-icon>mdi-history</v-icon>
         </v-btn>
      </template>
      <span>ประวัติข้อมูล</span>
    </v-tooltip>
               <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-btn icon  v-bind="attrs"
          v-on="on" @click="assignGroup(item)">
            <v-icon>mdi-file-move-outline</v-icon>
         </v-btn>
      </template>
      <span>ส่งต่อไปยังกลุ่มอื่น</span>
    </v-tooltip>
     <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-btn icon  v-bind="attrs"
          v-on="on" @click="viewMessage(item)">
            <v-icon>mdi-email</v-icon>
         </v-btn>
      </template>
      <span>ข้อความ</span>
    </v-tooltip>
           <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click="deleteFromGroup(item)"  v-bind="attrs"
          v-on="on">
            <v-icon>mdi-delete-outline</v-icon>
         </v-btn>
      </template>
      <span>ออกจากกลุ่ม</span>
    </v-tooltip>
         </v-row>
       </template>
    </DataTable>
      <v-dialog v-model="assignUserDialog" width="450" persistent scrollable >
      <AssignUserCard @onClose="assignUserDialog=false" @onSubmit="postGroupPatient" :action="'บันทึก'" v-model="currentPatient" :key="assignUserDialog" :groupItems="groupItems"/>
    </v-dialog>
      <v-dialog v-model="deleteDialog" width="384" persistent>
        <ConfirmDeleteCard :title="'คุณต้องการย้ายผู้ป่วยออกจากกลุ่มหรือไม่'" :subtitle="'หากคุณทำการย้ายข้อมูล รายการนี้จะออกจากกลุ่มติดตามข้อมูลและจะต้องทำรายการใหม่อีกครั้ง'" :action="'ย้ายข้อมูลนี้'" @onClose="deleteDialog=false" @onConfirm="deleteGroupPatient()" />
        </v-dialog>
      <v-dialog style="z-index: 9998" v-model="historyDialog" persistent scrollable fullscreen>
        <HistoryCard @onClose="historyDialog=false" v-bind="history" />
      </v-dialog>
      <v-dialog style="z-index: 9998" v-model="locationDialog" persistent scrollable fullscreen>
        <MapCard @onClose="locationDialog=false" :lat="Number(lat)" :long="Number(long)" />
      </v-dialog>
        <v-dialog style="z-index: 9998" v-model="imageDialog" persistent scrollable fullscreen>
        <ImageCard @onClose="imageDialog=false" :data="image" :formId="formId" :key="imageDialog" />
      </v-dialog>
        <!-- <v-dialog style="z-index: 9998" v-model="preview" width="384" persistent scrollable >
        <PreviewCard @onClose="preview=false" @onConfirm="postFormResult"/>
      </v-dialog> -->
      <v-dialog scrollable persistent v-model="preview" width="384">
         <PreviewCard @onClose="preview=false" @onConfirm="postFormResult"/>
      </v-dialog>
      <v-dialog v-model="messageDialog" width="600" persistent scrollable >
      <ChatAreaCard @onClose="messageDialog=false" :chatThread="threadId" :key="messageDialog" v-bind="currentPatient"/>
    </v-dialog>
        <v-dialog v-model="confirmDialog" width="450" persistent scrollable>
      <ConfirmDeleteCard @onClose="confirmDialog = false" @onConfirm="confirmDialog = false" :subtitle="'ไม่พบ Chat ID ในระบบ เนื่องจากข้อมูลผู้ป่วยถูกสร้างขึ้นในระบบ หรือผู้ป่วยถูกรายงานข้อมูลจากผู้ป่วยท่านอื่น'" :title="'แจ้งเตือน'" :action="'รับทราบ'"/>
    </v-dialog>
     <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
import DatePicker from '@/components/common/DatePicker'
import FilterBar from '@/components/common/FilterBar'
import DataTable from '@/components/common/DataTable'
import TextField from '@/components/common/TextField'
import Select from '@/components/common/Select'
import { getGroup, getMonitor, getForm, postGroupPatient, deleteGroupPatient, getMonitorHistory, postFormResult, getChatThread, postChatThread } from '@/api/'
import { mapFields } from 'vuex-map-fields'
import AssignUserCard from '@/components/patient/AssignUserCard'
import HistoryCard from '@/components/monitor/HistoryCard'
import ChatAreaCard from '@/components/chat/ChatAreaCard'
import { xlsxMixins } from '@/mixins/xlsx'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

export default {
  mixins: [xlsxMixins],
  components: {
    TitleBar,
    DatePicker,
    FilterBar,
    DataTable,
    Select,
    AssignUserCard,
    HistoryCard,
    ChatAreaCard,
    TextField,
    ConfirmDeleteCard: () => import('@/components/common/ConfirmCard'),
    MapCard: () => import('@/components/monitor/MapCard'),
    ImageCard: () => import('@/components/monitor/ImageCard'),
    PreviewCard: () => import('@/components/form/SaveAnsCard')
  },
  data () {
    return {
      items: [],
      selectedDate: new Date().toISOString().substr(0, 10),
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      assignUserDialog: false,
      headers: [],
      loading: false,
      dataLoading: false,
      search: null,
      yearItems: [],
      formTypeItems: [],
      formListItems: [],
      formId: null,
      formType: null,
      year: null,
      group: null,
      groupItems: [],
      currentPatient: null,
      deleteDialog: false,
      historyDialog: false,
      history: null,
      lat: 0,
      long: 0,
      image: null,
      locationDialog: false,
      imageDialog: false,
      polling: null,
      preview: false,
      threadId: null,
      messageDialog: false,
      confirmDialog: false
    }
  },
  computed: {
    ...mapFields(['form']),
    objectColumns () {
      return this.headers.filter(val => val.type === 'select')
    },
    numberColumns () {
      return this.headers.filter(val => val.inputType === 'number')
    },
    locationColumns () {
      return this.headers.filter(val => val.type === 'location')
    },
    imageColumns () {
      return this.headers.filter(val => val.type === 'image')
    }
  },
  watch: {
    formId (val) {
      if (val) {
        this.getResult()
      } else {
        this.items = []
        this.headers = []
      }
    },
    selectedDate () {
      this.getResult()
    },
    group () {
      this.getResult()
    }
  },
  methods: {
    pollData () {
      this.polling = setInterval(() => {
        this.getResult()
      }, 10000)
    },
    getImage (item) {
      this.image = item
      this.imageDialog = true
    },
    getMap (item) {
      if (!item.includes(',')) return
      const position = item.split(',')
      this.lat = position[0]
      this.long = position[1]
      this.locationDialog = true
    },
    getAlertColor (header, val) {
      // console.log(header)
      // console.log(val)
      if (!header.alert) return ''
      const filter = header.alert.filter(dat => Number(val) >= dat.min && Number(val) <= dat.max)
      if (filter.length === 0) return ''
      return filter[0].color
    },
    viewHistory (item) {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getMonitorHistory({ tenantId: tenant.tenantId, siteId: site.siteId, cid: item.cid, formTypeId: this.formId.formTypeId, offset: 0, limit: 1000, formId: this.formId.formId }, message => {
        if (message.data.code === 1) {
          message.data.result.headers = message.data.result.headers.map(this.getHeader)
          let res = message.data.result.headers.filter(val => val.value === 'id_number')
          if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
          res = message.data.result.headers.filter(val => val.value === 'address_th')
          if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
          res = message.data.result.headers.filter(val => val.value === 'name_th')
          if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
          res = message.data.result.headers.filter(val => val.value === 'formResultId')
          if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
          res = message.data.result.headers.filter(val => val.value === 'formId')
          if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
          res = message.data.result.headers.filter(val => val.value === 'formName')
          if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
          res = message.data.result.headers.filter(val => val.value === 'formTypeId')
          if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
          res = message.data.result.headers.filter(val => val.value === 'formTypeName')
          if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
          // console.log(message.data.result.headers)
          this.history = message.data.result
          this.historyDialog = true
        }
      }, error => {
        console.log(error)
      })
    },
    assignGroup (item) {
      this.currentPatient = item
      this.assignUserDialog = true
    },
    deleteFromGroup (item) {
      this.currentPatient = item
      this.deleteDialog = true
    },
    deleteGroupPatient () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      this.deleteDialog = false
      deleteGroupPatient({ tenantId: tenant.tenantId, siteId: site.siteId, id: this.currentPatient.groupPatientId }, message => {
        if (message.data.code === 1) {
          this.getResult()
        }
      }, error => {
        console.log(error)
      })
    },
    postGroupPatient () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      postGroupPatient({ tenantId: tenant.tenantId, siteId: site.siteId, cid: this.currentPatient.cid, groupId: this.currentPatient.groupId }, message => {
        if (message.data.code === 1) {
          this.assignUserDialog = false
          this.getResult()
        }
      }, error => {
        console.log(error)
      })
    },
    getHeader (val) {
      const header = { ...val, class: 'primary lighten-5 fixed', ...this.formId.schema.fields.filter(dat => dat.name === val.value)[0] }
      return header
    },
    getResult () {
      if (!this.formId || !this.group) return
      this.dataLoading = true
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      getMonitor({ tenantId: tenant.tenantId, siteId: site.siteId, groupId: this.group.id, formTypeId: this.formId.formTypeId, offset: 0, limit: 1000, formId: this.formId.formId, date: this.selectedDate, startDate: this.startDate, endDate: this.endDate, keyword: (this.search) ? this.search : '' }, message => {
        if (message.data.code === 1) {
          if (message.data.result) {
            this.items = message.data.result.items
            message.data.result.headers = message.data.result.headers.map(this.getHeader)
            let res = message.data.result.headers.filter(val => val.value === 'id_number')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            res = message.data.result.headers.filter(val => val.value === 'address_th')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            res = message.data.result.headers.filter(val => val.value === 'name_th')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            res = message.data.result.headers.filter(val => val.value === 'formResultId')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            res = message.data.result.headers.filter(val => val.value === 'formId')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            res = message.data.result.headers.filter(val => val.value === 'formName')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            res = message.data.result.headers.filter(val => val.value === 'formTypeId')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            res = message.data.result.headers.filter(val => val.value === 'formTypeName')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            res = message.data.result.headers.filter(val => val.value === 'monitorByUserId')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            res = message.data.result.headers.filter(val => val.value === 'monitorByUserName')
            if (res.length !== 0) message.data.result.headers.splice(message.data.result.headers.indexOf(res[0]), 1)
            message.data.result.headers.push({ text: 'ดำเนินการ', value: 'action', class: 'primary lighten-5 fixed', width: 200, align: 'center fixed' })
            this.headers = message.data.result.headers
          }
        }
        this.dataLoading = false
      }, error => {
        this.dataLoading = false
        console.log(error)
      })
    },
    buildParam (param, data) {
      let ret = ''
      for (let i = 0; i < data.length; i++) {
        if (i < 1) {
          ret += param + '=' + data[i]
        } else {
          ret += '&' + param + '=' + data[i]
        }
      }
      return ret
    },
    onSaveData (item) {
      Object.assign(this.formId, item)
      this.form = _.cloneDeep(this.formId)
      this.preview = true
    },
    postFormResult () {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      const site = JSON.parse(sessionStorage.getItem('Site'))
      this.preview = false
      const data = { arrResult: [{ ...this.form, ...{ formResultId: uuidv4() }, id_number: this.form.cid, address_th: this.form.address, name_th: this.form.fullname }], tenantId: tenant.tenantId, siteId: site.siteId }
      // console.log(data)
      postFormResult(data, message => {
        if (message.data.code === 1) {
          this.getResult()
        } else {
          // this.getResult()
        }
      }, error => {
        console.log(error)
      })
    },
    async viewMessage (item) {
      try {
        this.loading = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        const site = JSON.parse(sessionStorage.getItem('Site'))
        const thread = await getChatThread({ tenantId: tenant.tenantId, siteId: site.siteId, cid: item.cid, offset: 0, limit: 1 })
        if (thread.data.code === 1) {
          if (thread.data.result.items[0].chatThread) {
            this.threadId = thread.data.result.items[0].chatThread
            this.currentPatient = item
            this.messageDialog = true
          } else {
            const postThread = await postChatThread({ tenantId: tenant.tenantId, siteId: site.siteId, cid: item.cid })
            if (postThread.data.code === 1) {
              this.threadId = postThread.data.result
              this.currentPatient = item
              this.messageDialog = true
            }
          }
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 409) {
            console.log('ไม่พบ Chat ID ในระบบ เนื่องจากข้อมูลผู้ป่วยถูกสร้างขึ้นในระบบ หรือผู้ป่วยถูกรายงานข้อมูลจากผู้ป่วยท่านอื่น')
            this.confirmDialog = true
          }
        }
      } finally {
        this.loading = false
      }
    },
    async fetchData () {
      this.loading = true
      if (this.$auth.role) {
        try {
          const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
          const site = JSON.parse(sessionStorage.getItem('Site'))
          const resp = await getGroup({
            tenantId: tenant.tenantId,
            siteId: site.siteId
          })
          if (resp.data.code === 1) {
            this.groupItems = resp.data.result.items
            this.group = resp.data.result.items[0]
          } else {
            this.groupItems = []
          }
          getForm({
            tenantId: tenant.tenantId,
            siteId: site.siteId,
            offset: 0,
            limit: 100,
            formTypeId: '',
            year: '',
            active: this.buildParam('active', [true])
          }, message => {
            if (message.data.code === 1) {
              if (message.data.result) {
                this.formListItems = message.data.result.items
              } else {
                this.formListItems = []
              }
            }
            this.dataLoading = false
          }, error => {
            this.dataLoading = false
            console.log(error)
          })
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      }
    }
  },
  created () {
    this.startDate = this.$dayjs().subtract(3, 'month').format('YYYY-MM-DD')
    this.endDate = this.$dayjs().format('YYYY-MM-DD')
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
        this.pollData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  beforeRouteLeave (to, from, next) {
    this.form = {}
    next()
  },
  beforeDestroy () {
    clearInterval(this.polling)
  }
}
</script>

<style>
table > tbody > tr > td.fixed:nth-last-child(1),
table > thead > tr > th.fixed:nth-last-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0;
  z-index: 9998;
  background: white;
  -webkit-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
  box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.19);
}

table > thead > tr > th.fixed:nth-last-child(1) {
  z-index: 9999;
}
</style>
