<template>
  <v-card>
      <v-card-title class="text-h4 font-weight-bold">ประวัติข้อมูลผู้ป่วย<v-spacer/><v-btn @click="$emit('onClose')" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
        <label>
          สรุปข้อมูลค่าเฉลี่ย 7 ค่าล่าสุด
        </label>
     <v-data-table :items="analysisItems" :headers="numberColumns">
    </v-data-table>
</v-col>
</v-row>
<v-row>
  <v-col cols="12">
  <label>
          กราฟข้อมูล
        </label>
        <apexchart width="100%" height=300 type="line" :options="chartOptions" :series="dataseries"></apexchart>
      </v-col></v-row>
      <v-row><v-col cols="12">
        <label>
          ข้อมูลทั้งหมด
        </label>
        <v-data-table :items="items" :headers="headers">
      <template v-slot:item.createDate="{item}">
         {{$dayjs(new Date(item.createDate) ).locale('th').format('DD MMMM BBBB HH:mm')}}
       </template>
            <template v-for="objectColumn in objectColumns" v-slot:[`item.${objectColumn.value}`]="{ item }">
    <div :key="objectColumn.value">
          {{(typeof item[objectColumn.value] === 'object')? item[objectColumn.value].title: item[objectColumn.value]}}
    </div>
      </template>
        <template v-for="numberColumn in numberColumns" v-slot:[`item.${numberColumn.value}`]="{ item }">
    <div :key="numberColumn.value">
      <v-chip :color="getAlertColor(numberColumn,item[numberColumn.value])">
          {{item[numberColumn.value]}}
      </v-chip>
    </div>
      </template>
       <template v-for="locationColumn in locationColumns" v-slot:[`item.${locationColumn.value}`]="{ item }">
    <div :key="locationColumn.value">
<v-btn icon @click="getMap(item[locationColumn.value])"><v-icon>mdi-google-maps</v-icon></v-btn>
    </div>
      </template>
 <template v-for="imageColumn in imageColumns" v-slot:[`item.${imageColumn.value}`]="{ item }">
    <div :key="imageColumn.value">
<v-btn icon @click="getImage(item[imageColumn.value])"><v-icon>mdi-file-image</v-icon></v-btn>
    </div>
      </template>
        </v-data-table>
      </v-col></v-row>
      </v-card-text>
      <v-card-actions>
      </v-card-actions>
       <v-card-actions></v-card-actions>
 <v-dialog style="z-index: 9998" v-model="locationDialog" persistent scrollable fullscreen>
        <MapCard @onClose="locationDialog=false" :lat="Number(lat)" :long="Number(long)" />
      </v-dialog>
        <v-dialog style="z-index: 9998" v-model="imageDialog" persistent scrollable fullscreen>
        <ImageCard @onClose="imageDialog=false" :data="image" />
      </v-dialog>
  </v-card>
</template>

<script>
// import DataTable from '@/components/common/DataTable'
import VueApexCharts from 'vue-apexcharts'
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    status: {
      type: Boolean
    },
    items: {
      type: Array
    },
    headers: {
      type: Array
    },
    value: {
      type: Object
    }
  },
  components: {
    MapCard: () => import('@/components/monitor/MapCard'),
    ImageCard: () => import('@/components/monitor/ImageCard'),
    apexchart: VueApexCharts
  },
  data () {
    return {
      valid: false,
      imageDialog: false,
      locationDialog: false,
      image: null,
      lat: 0,
      long: 0,
      // analysisItems: [],
      chartOptions: {
        chart: {
          shadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },
          toolbar: {
            show: true
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth',
          width: 2,
          dashArray: 0
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false
          }
        }
      }
    }
  },
  computed: {
    objectColumns () {
      return this.headers.filter(val => val.type === 'select')
    },
    numberColumns () {
      return this.headers.filter(val => val.inputType === 'number')
    },
    locationColumns () {
      return this.headers.filter(val => val.type === 'location')
    },
    imageColumns () {
      return this.headers.filter(val => val.type === 'image')
    },
    analysisItems () {
      const item = {}
      this.numberColumns.forEach((column) => {
        let sum = 0
        let counter = 0
        this.items.forEach((element) => {
          if (counter < 7) {
            if (element[column.value] && element[column.value] < 300) {
              sum += Number(element[column.value])
              counter++
            }
          }
        })
        console.log(counter)
        item[column.value] = Math.ceil(sum / counter)
      })
      return [item]
    },
    dataseries () {
      const column = this.headers.filter(val => val.inputType === 'number')
      const series = []
      column.forEach((item) => {
        series.push(
          {
            name: item.title,
            ref: item.name,
            data: []
          }
        )
      })
      this.items.forEach((item) => {
        series.forEach((header) => {
          if (item[header.ref]) {
            header.data.push([item.createDate, item[header.ref]])
          }
        })
      })
      return series
    }
  },
  mounted () {
    // .analysis()
  },
  methods: {
    onSubmit () {
      if (this.$refs.form.validate()) {
        this.$emit('onSubmit')
      }
    },
    getImage (item) {
      this.image = item
      this.imageDialog = true
    },
    getMap (item) {
      const position = item.split(',')
      this.lat = position[0]
      this.long = position[1]
      this.locationDialog = true
    },
    getAlertColor (header, val) {
      if (!header.alert) return ''
      const filter = header.alert.filter(dat => Number(val) >= dat.min && Number(val) <= dat.max)
      if (filter.length === 0) return ''
      return filter[0].color
    }
  }
}
</script>

<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>
